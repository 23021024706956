<template>
  <div>
    <div class="title-tabs">
      <b-row>
        <b-col md="6" class="text-left">General Information</b-col>
      </b-row>
    </div>

    <b-container class="no-gutters bg-white p-2 p-md-3">
      <InputTextArea
        v-model="form.redeem_condition_message"
        textFloat="Redeem Condition Message"
        type="text"
        class="mt-3 custom-input"
        placeholder="Redeem Condition Message"
        rows="3"
    /></b-container>
  </div>
</template>
<script>
export default {
  props: {
    form: { require: true },
  },
  methods: {
    deleteImage() {
      this.form.theme_background = "";
    },
  },
};
</script>
<style lang="scss">
.image-upload-preview-box {
  height: 159px;
  width: 345px;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
}
</style>
